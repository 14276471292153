@charset "UTF-8";
/* ================================================================= 
 License : e-TRUST Inc.
 File name : common.css
 Style : common
================================================================= */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 14px !important;
  line-height: 1.3em;
  overflow: visible !important;
}

body {
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.6;
  background: #333;
  color: #ffffff;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 14px;
  word-wrap: break-word;
  overflow-x: hidden;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  }
}

@media screen and (max-width: 1000px) {
  #main {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  #main #col_main {
    width: 100%;
  }
}

figure {
  padding: 0;
  margin: 0;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

/***********************************サイドバー*******************************************/
@media screen and (max-width: 1000px) {
  #side {
    width: 18.75000%;
  }
}

@media screen and (max-width: 1000px) {
  #side aside {
    width: 100% !important;
  }
}

/***********************************非表示*******************************************/
.dnone {
  display: none;
}

/***********************************フレックス*******************************************/
.flexleft {
  display: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
}

.flexcenter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

/***********************************書体*******************************************/
.mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

/***********************************カラムフロート*******************************************/
.ColLeft {
  float: left;
}

.ColRight {
  float: right;
}

/***********************************フロート*******************************************/
@media screen and (max-width: 834px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
}

.tacenter {
  text-align: center !important;
}

ul.break li {
  float: none !important;
}

/***********************************テキスト*******************************************/
b, strong {
  font-weight: bold;
}

.b {
  font-weight: bold;
}

span {
  font-weight: inherit;
}

a:hover {
  opacity: 0.6;
}

.link1 a {
  color: #104480;
  font-weight: bold;
}

u {
  text-decoration: underline;
}

small {
  font-size: 80%;
}

.fwb {
  font-weight: bold;
}

/***********************************線*******************************************/
hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

/************************************テーブル************************************/
table {
  table-layout: fixed;
}

table td {
  padding: 20px;
}

.tlauto table {
  table-layout: auto;
}

table td img {
  height: auto !important;
}

.tdpd0 td {
  padding: 0px !important;
}

.tdpd5 td {
  padding: 5px !important;
}

.tdpd10 td {
  padding: 10px !important;
}

@media screen and (max-width: 640px) {
  .tdpd10 td {
    padding: 5px !important;
  }
}

.tdpd15 td {
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .tdpd15 td {
    padding: 5px !important;
  }
}

.tdpd20 td {
  padding: 20px !important;
}

@media screen and (max-width: 640px) {
  .tdpd20 td {
    padding: 5px !important;
  }
}

.top_pa20 {
  padding-top: 20px !important;
}

.bottom_pa20 {
  padding-bottom: 20px !important;
}

.cnt_table table {
  width: 100% !important;
}

@media screen and (max-width: 834px) {
  .cnt_table table {
    margin: 0 auto;
  }
}

.cnt_table table tr td {
  vertical-align: top;
}

@media screen and (max-width: 640px) {
  .cnt_table table tr td {
    width: 100% !important;
  }
}

.tdsp_left {
  text-align: left !important;
}

@media screen and (max-width: 640px) {
  .tdsp_left {
    width: 100% !important;
  }
}

/************************************スマホ、タブレット時表示************************************/
.sp_tel a {
  text-decoration: none;
  pointer-events: none;
}

@media screen and (max-width: 834px) {
  .sp_tel a {
    pointer-events: auto;
  }
}

.sp_tel_text a {
  color: #fff !important;
  text-decoration: none;
  pointer-events: none;
}

@media screen and (max-width: 834px) {
  .sp_tel_text a {
    pointer-events: auto;
    text-decoration: underline;
  }
}

/************************************ボーダー************************************/
.br0 td {
  border: 0 !important;
}

.border01 {
  border: solid 1px #23c5b5;
}

.border02 {
  border: solid 1px #053833;
}

.border03 {
  border: solid 1px #079bcb;
}

.border04 {
  border: solid 1px #b552ad;
}

.border01_4 {
  border: solid 4px #23c5b5;
}

.border02_4 {
  border: solid 4px #053833;
}

.border03_4 {
  border: solid 4px #079bcb;
}

.border04_4 {
  border: solid 4px #b552ad;
}

.border_f {
  border: solid 1px #fff;
}

/************************************ボーダーボトム************************************/
.btm01 {
  border-bottom: 1px dotted #23c5b5 !important;
}

.btm02 {
  border-bottom: 1px dotted #053833 !important;
}

.btm03 {
  border-bottom: 1px dotted #079bcb !important;
}

.btm04 {
  border-bottom: 1px dotted #b552ad !important;
}

/************************************バックグラウンド************************************/
.bgf {
  background: #fff !important;
}

.bgf05 {
  background: rgba(255, 255, 255, 0.5);
}

.bgf02 {
  background: rgba(255, 255, 255, 0.2);
}

.tb_bgf table {
  background: #fff;
}

/************************************ボタン************************************/
.btn01 {
  text-align: right !important;
}

.btn01 a {
  position: relative;
  padding: 15px;
  max-width: 300px;
  background: #333;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: #fff !important;
  font-size: 20px;
  text-align: center;
  margin: 0 0 0 auto;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (max-width: 640px) {
  .btn01 a {
    width: 100%;
    text-align: center;
  }
}

.btn01 a:after {
  position: absolute;
  content: "";
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 34px;
  background: url(../images/common/btn01.png);
}

/************shadow_box*******************/
.shadow_box {
  box-shadow: 2px 2px 8px #ccc;
  border: 1px solid #ccc;
}

/************************************インナー************************************/
.inner {
  margin: 0 auto;
  max-width: 1000px;
}

@media screen and (max-width: 1000px) {
  .inner {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 640px) {
  .inner {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.inner1200 {
  max-width: 1200px;
  margin: 0 auto;
  width: 95%;
}

.inner1100 {
  max-width: 1100px;
  margin: 0 auto;
  width: 95%;
}

/************************************コンテンツ************************************/
#contents_wrap {
  padding: 0px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-width: auto !important;
}

/************************************画像RL************************************/
.imgL {
  margin-right: 30px;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .imgL {
    margin-right: 20px;
  }
}

@media screen and (max-width: 640px) {
  .imgL {
    display: block;
    float: none;
    margin: 0 auto 20px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgR {
  margin-left: 30px;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .imgR {
    margin-left: 20px;
  }
}

@media screen and (max-width: 640px) {
  .imgR {
    display: block;
    float: none;
    margin: 0 auto 20px;
    max-width: 100% !important;
    text-align: center;
  }
}

img {
  max-width: 100%;
  height: auto !important;
}

.img100 img {
  width: 100% !important;
}

.img80 img {
  width: 80% !important;
}

.img50 img {
  width: 50% !important;
}

.center_img {
  text-align: center;
}

/************************************プライバシーポリシー************************************/
.modaal-content-container {
  color: #fff;
  background: #000;
  font-size: 16px;
}

.modaal-content-container h2 {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #23c5b5;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 20px;
}

/************************************サイトマップ************************************/
.map {
  max-width: 1000px;
  width: 95%;
}

.map li {
  border-left: 3px solid #23c5b5;
  list-style-type: none;
  padding-left: 10px;
  margin-bottom: 10px;
  display: block;
  float: none !important;
}

.map li a {
  color: #282828;
  font-size: 16px;
  text-decoration: none;
}

.map li a:hover {
  text-decoration: underline;
}

/************************************コンタクトフォーム************************************/
.contact_form {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.contact_form p {
  font-size: 16px;
}

.contact_form table td {
  padding: 15px !important;
  border: #23c5b5 solid 1px !important;
}

@media screen and (max-width: 834px) {
  .contact_form table td {
    display: block;
    width: 100%;
  }
}

.contact_form table th {
  width: 30%;
  border: #23c5b5 solid 1px !important;
  background: #333 !important;
  color: #23c5b5;
  font-weight: bold;
  font-size: 16px !important;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .contact_form table th {
    display: block;
    width: 100% !important;
    text-align: center;
  }
}

/************************************コンタクトフォーム(AI用)************************************/
.contact_form {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.contact_form dt {
  font-size: 16px;
}

.contact_form dl dt {
  padding: 15px !important;
  text-align: center;
  background: #333 !important;
  font-weight: bold;
  color: #23c5b5;
  align-items: center;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (max-width: 834px) {
  .contact_form dl dt {
    display: block;
    width: 40% !important;
  }
}

@media screen and (max-width: 640px) {
  .contact_form dl dt {
    display: block;
    width: 100%;
  }
}

.contact_form dl dd {
  font-size: 16px !important;
}

@media screen and (max-width: 834px) {
  .contact_form dl dd {
    display: block;
    width: 100% !important;
  }
}

/***************************************************/
.submit {
  margin: 20px auto 50px auto !important;
}

/*********************PDFボタン******************************/
.pdf_btn {
  padding: 10px 0;
}

.pdf_btn a {
  display: block;
  padding: 20px 5px;
  background: #d66464;
  position: relative;
  text-align: center;
  font-size: 16px;
  color: #fff !important;
  text-decoration: none;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (max-width: 640px) {
  .pdf_btn a {
    margin: 0 auto;
    max-width: 300px;
  }
}

.pdf_btn a:after {
  content: "";
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border: 1px #fff solid;
}

.pdf_btn a:before {
  content: "PDF";
  display: block;
  width: calc(100% - 50px);
  position: absolute;
  right: -50px;
  bottom: -10px;
  color: rgba(255, 255, 255, 0.2);
  font-size: 50px;
}

/************************************ボタン************************************/
input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

.pankuzu a:link {
  color: #23c5b5 !important;
  text-decoration: none !important;
}

.pankuzu a:visited {
  color: #23c5b5 !important;
}

@media screen and (max-width: 834px) {
  .pankuzu {
    padding: 10px 0;
  }
}

/*******************************************************************/
.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

/******************PCの時表示　タブレット時の非表示**************************************/
.display_pc {
  text-align: center;
}

@media screen and (max-width: 834px) {
  .display_pc {
    display: none;
  }
}

.display_pc01 {
  text-align: center;
}

@media screen and (max-width: 640px) {
  .display_pc01 {
    display: none;
  }
}

/******************タブレットの時表示　PC時の非表示**************************************/
.display_tab {
  display: none;
}

@media screen and (max-width: 834px) {
  .display_tab {
    text-align: center;
    display: block;
  }
}

/******************タブレットの時表示　PC時の非表示**************************************/
.display_sp {
  display: none;
}

@media screen and (max-width: 640px) {
  .display_sp {
    text-align: center;
    display: block;
  }
}

/******************スマホ時　ブロック**************************************/
.sp_block {
  display: inline;
}

@media screen and (max-width: 640px) {
  .sp_block {
    display: block;
  }
}

/************************************PC時ブロック スマホ時インライン************************************/
.pc_block {
  display: block;
}

@media screen and (max-width: 640px) {
  .pc_block {
    display: inline;
  }
}

/******************flexwrap*************************************/
.flexwrap {
  display: flex;
  flex-wrap: wrap;
}

.w23 {
  width: 23%;
  margin: 0 1%;
}

@media screen and (max-width: 834px) {
  .w23 {
    width: 48%;
  }
}

.w31 {
  width: 31.11111111%;
  margin: 0 1%;
}

@media screen and (max-width: 834px) {
  .w31 {
    width: 48%;
  }
}

.w31_tab48 {
  width: 31.11111111%;
  margin: 0 1%;
}

@media screen and (max-width: 834px) {
  .w31_tab48 {
    width: 48%;
  }
}

@media screen and (max-width: 640px) {
  .w31_tab48 {
    width: 100%;
  }
}

.w31_tab100 {
  width: 31.11111111%;
  margin: 0 1%;
}

@media screen and (max-width: 834px) {
  .w31_tab100 {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .w31_tab100 {
    width: 48%;
  }
}

@media screen and (max-width: 480px) {
  .w31_tab100 {
    width: 100%;
  }
}

.w23_sp100 {
  width: 24.5%;
  margin: 10px 0.25% !important;
}

@media screen and (max-width: 834px) {
  .w23_sp100 {
    width: 31%;
    margin: 10px 1% !important;
  }
}

@media screen and (max-width: 640px) {
  .w23_sp100 {
    width: 48%;
  }
}

@media screen and (max-width: 480px) {
  .w23_sp100 {
    width: 100%;
  }
}

.w48 {
  width: 48%;
  margin: 0 1%;
}

@media screen and (max-width: 640px) {
  .w48 {
    width: 100%;
  }
}

.w48_100 {
  width: 48%;
  margin: 0 1%;
}

@media screen and (max-width: 834px) {
  .w48_100 {
    width: 100%;
  }
}

.w58 {
  width: 58%;
  margin: 0 1%;
}

@media screen and (max-width: 640px) {
  .w58 {
    width: 100%;
  }
}

.w38 {
  width: 38%;
  margin: 0 1%;
}

@media screen and (max-width: 640px) {
  .w38 {
    width: 100%;
  }
}

.w58_100 {
  width: 58%;
  margin: 0 1%;
}

@media screen and (max-width: 834px) {
  .w58_100 {
    width: 100%;
  }
}

.w38_100 {
  width: 38%;
  margin: 0 1%;
}

@media screen and (max-width: 834px) {
  .w38_100 {
    width: 100%;
  }
}

/******************flexwrap(フレックスボックス)*************************************/
.flexwrap31 {
  display: flex;
  flex-wrap: wrap;
}

.flexwrap31 .box {
  width: 31.11111111%;
  margin: 20px 1%;
}

@media screen and (max-width: 834px) {
  .flexwrap31 .box {
    width: 48%;
  }
}

/******************flexwrap(フレックスボックス)*************************************/
.flexwrap23 {
  display: flex;
  flex-wrap: wrap;
}

.flexwrap23 .box {
  width: 23%;
  margin: 20px 1%;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .flexwrap23 .box {
    width: 48%;
  }
}

.flexwrap23_tab4 {
  display: flex;
  flex-wrap: wrap;
}

.flexwrap23_tab4 .box {
  width: 23%;
  margin: 20px 1%;
  text-align: center;
}

/******************flexwrap(フレックスボックス)*************************************/
.flexwrap18 {
  display: flex;
  flex-wrap: wrap;
}

.flexwrap18 .box {
  width: 18%;
  margin: 20px 1% 20px 1%;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .flexwrap18 .box {
    width: 23%;
    margin: 10px 1% 0 1%;
  }
}

.flexwrap23_tab4 {
  display: flex;
  flex-wrap: wrap;
}

.flexwrap23_tab4 .box {
  width: 23%;
  margin: 20px 1%;
  text-align: center;
}

/*******************テキスト(ai用)*******************************************/
@media screen and (max-width: 640px) {
  .cnt_text div {
    text-align: left !important;
  }
}

/*************************テーブルリスト(ボタン横列)***********************************/
.link_list {
  padding: 20px 0;
}

@media screen and (max-width: 640px) {
  .link_list {
    padding: 10px 0;
  }
}

.link_list ul {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  width: auto;
}

.link_list li {
  display: block;
  padding: 0 10px;
}

.link_list li a {
  width: 100%;
  padding: 10px;
  background: #fff;
  color: #053833 !important;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
  box-shadow: 2px 2px 5px #000;
  margin-top: 10px;
}

@media screen and (max-width: 834px) {
  .link_list li a {
    padding: 5px;
    text-align: left;
    font-size: 16px;
  }
}

@media screen and (max-width: 834px) {
  .link_list li a {
    padding: 3px;
    text-align: left;
    font-size: 14px;
  }
}

/*************************ページ内リンク01***********************************/
.link_name {
  margin-top: -30px;
  padding-top: 30px;
}

@media screen and (max-width: 834px) {
  .link_name {
    margin-top: -30px;
    padding-top: 30px;
  }
}

/*************************ページ内リンク01***********************************/
.link_name01 {
  margin-top: -80px;
  padding-top: 80px;
}

@media screen and (max-width: 834px) {
  .link_name01 {
    margin-top: -30px;
    padding-top: 30px;
  }
}

/*************************ノーホバー***********************************/
.no_events {
  pointer-events: none;
}

.no_events:hover {
  opacity: 1 !important;
}

/***************フェードイン**************************/
.fadein {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

@media screen and (max-width: 834px) {
  .fadein {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.fadein.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

/****************************会社概要************************************/
.information_dl {
  font-size: 14px;
}

.information_dl dl {
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 640px) {
  .information_dl dl {
    display: block !important;
    width: 100% !important;
    border: 0 !important;
  }
}

.information_dl dl dt {
  font-weight: bold;
  background: none !important;
  text-align: center !important;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff !important;
  align-items: center !important;
}

@media screen and (max-width: 640px) {
  .information_dl dl dt {
    width: 100% !important;
    background: #222 !important;
  }
}

@media screen and (max-width: 640px) {
  .information_dl dl dt {
    width: 100% !important;
    border: 0 !important;
  }
}

.information_dl dl dd {
  background: none !important;
}

@media screen and (max-width: 640px) {
  .information_dl dl dd {
    display: block !important;
    width: 100% !important;
    border: 0 !important;
  }
}

/****************************画像回転************************************/
.rotate5 img {
  -moz-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
}

.rotate8 img {
  -moz-transform: rotate(8deg);
  -webkit-transform: rotate(8deg);
  -o-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
}

.rotate-10 img {
  -moz-transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

/******************会社概要(AI用)dldt_list01**************************************/
.dldt_list01 {
  display: block;
  width: 100%;
  margin: 0 1%;
}

.dldt_list01 dl {
  width: 100%;
  border-bottom: 1px dotted #23c5b5 !important;
}

@media screen and (max-width: 640px) {
  .dldt_list01 dl {
    display: block !important;
  }
}

.dldt_list01 dl dt {
  font-weight: bold;
  background: #333 !important;
  color: #23c5b5 !important;
  display: table-cell !important;
  border: 0px !important;
  vertical-align: middle;
  text-align: center;
  display: block !important;
}

@media screen and (max-width: 640px) {
  .dldt_list01 dl dt {
    width: 100% !important;
    display: block !important;
  }
}

.dldt_list01 dl dd {
  display: block !important;
  border: 0px !important;
}

@media screen and (max-width: 640px) {
  .dldt_list01 dl dd {
    width: 100% !important;
  }
}

/******************トップお問い合わせ**************************************/
.top_contact {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
}

.top_contact:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 404px;
  height: 312px;
  background: url(../images/common/contact_bg.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 30%;
}

@media screen and (max-width: 834px) {
  .top_contact:after {
    display: none;
  }
}

.top_contact article {
  max-width: 640px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.top_contact article td {
  border: 0 !important;
}

.top_contacth2 h2 {
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding-bottom: 30px;
  font-size: 32px;
  color: #fff;
  position: relative;
  margin-bottom: 30px;
}

.top_contacth2 h2:after {
  content: "";
  max-width: 300px;
  height: 1px;
  width: 40%;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  background: #23c5b5;
}

.top_contacth2 h2 span {
  font-size: 20px;
  color: #fff;
  display: block;
  margin-top: 20px;
}

@media screen and (max-width: 834px) {
  .top_contacth2 h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 640px) {
  .top_contacth2 h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .top_contacttel {
    font-size: 22px;
  }
}

@media screen and (max-width: 640px) {
  .top_contacttel {
    font-size: 20px;
  }
}

.top_contacttel a {
  color: #fff !important;
  font-size: 28px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  text-decoration: none;
}

@media screen and (max-width: 834px) {
  .top_contacttel a {
    font-size: 24px;
  }
}

@media screen and (max-width: 640px) {
  .top_contacttel a {
    font-size: 20px;
  }
}

/******************画像テーブル横並び**************************************/
.img_box04 tr {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .img_box04 tr {
    justify-content: center;
  }
}

.img_box04 td {
  width: 25% !important;
  border: 0;
  display: block;
  padding: 5px !important;
}

.img_box04 td p {
  padding-top: 10px;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .img_box04 td p {
    width: 100% !important;
    padding-bottom: 10px !important;
  }
}

.img_box04 td img {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 640px) {
  .img_box04 td img {
    width: auto !important;
    height: auto !important;
  }
}

@media screen and (max-width: 640px) {
  .img_box04 td {
    width: 48% !important;
  }
}

.img_box03 tr {
  display: flex;
  flex-wrap: wrap;
}

.img_box03 td {
  width: 33% !important;
  border: 0;
  display: block;
  padding: 5px !important;
}

@media screen and (max-width: 640px) {
  .img_box03 td {
    width: 100% !important;
  }
}

.img_box03 td p {
  padding-top: 10px;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .img_box03 td p {
    width: 100% !important;
    padding-bottom: 10px !important;
  }
}

.img_box03 td img {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 640px) {
  .img_box03 td img {
    width: auto !important;
    height: auto !important;
  }
}

@media screen and (max-width: 640px) {
  .img_box03 td {
    width: 100%;
  }
}

/***************AI用Instagram***************************/
/***************topInstagram***************************/
.top_instagram {
  margin: 0 auto;
  max-width: 1100px;
}

.top_instagram div.sns_list div.sns_video {
  width: 100%;
  padding: 0;
}

.top_instagram div.sns_list {
  display: flex;
  border: 0px;
  flex-direction: row;
  flex-wrap: wrap;
}

.top_instagram div.sns_list > div {
  width: 20%;
  margin: 0;
  display: flex;
  border-bottom: 0px;
}

@media screen and (max-width: 640px) {
  .top_instagram div.sns_list > div {
    width: 50%;
  }
}

.top_instagram div.sns_list > div:last-child {
  display: flex;
}

.top_instagram div.sns_list div.sns_photo {
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
}

.top_instagram div.sns_list div.sns_text {
  display: none;
}

.sns_photo img {
  height: 200px !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  .sns_photo img {
    height: auto !important;
  }
}

/***************cntInstagram***************************/
.cnt_insta div.sns_list div.sns_date {
  background: #23c5b5;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding: 8px;
}

.cnt_insta .caption {
  padding-top: 20px;
  font-size: 16px;
}

.cnt_insta .sns_photo {
  width: 30% !important;
}

@media screen and (max-width: 834px) {
  .cnt_insta .sns_photo {
    width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .cnt_insta .sns_photo {
    width: 100% !important;
    margin: 0 auto;
    text-align: center;
  }
}

.cnt_insta div.sns_list > div {
  border-bottom: 1px dashed #23c5b5;
}

@media screen and (max-width: 834px) {
  .cnt_insta div.sns_list > div {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.cnt_insta div.sns_list {
  border: 0 !important;
}

@media screen and (max-width: 834px) {
  .cnt_insta div.sns_list div.sns_text {
    width: 70% !important;
  }
}

@media screen and (max-width: 640px) {
  .cnt_insta div.sns_list div.sns_text {
    width: 100% !important;
    margin: 0 auto;
  }
}

@media screen and (max-width: 640px) {
  .cnt_insta .sns_photo img {
    width: 80% !important;
    height: auto !important;
    margin: 0 auto;
  }
}

/***************新着情報(AIブログ機能用)***************************/
.top_blog {
  height: 300px;
  border: 1px solid #053833;
}

@media screen and (max-width: 834px) {
  .top_blog {
    padding: 10px;
  }
}

.top_blog .blog_list {
  border: 0 !important;
}

.top_blog div.blog_list > div {
  border-bottom: dotted #053833 1px !important;
}

.top_blog .blog_date {
  font-size: 16px !important;
  color: #23c5b5 !important;
  font-weight: bold;
  display: inline-block;
  padding-right: 20px;
}

.top_blog .blog_text h3 {
  display: inline-block;
  font-size: 20px !important;
  font-weight: bold;
}

.top_blog .blog_text h3 a {
  color: #053833 !important;
  font-weight: bold;
}

.top_blog .blog_photo {
  display: none;
}

.top_blog div.detail {
  margin-top: 10px !important;
}

.top_blog div.blog_text {
  width: 100% !important;
  font-size: 16px !important;
}

/*********************AI用ブログ***********************/
.blog {
  background: url(../images/common/bg01.jpg) !important;
}

.blog a {
  color: #23c5b5 !important;
}

.blog a:visited {
  color: #23c5b5 !important;
}

.blog a:link {
  color: #23c5b5 !important;
}

.blog li {
  text-align: left !important;
}

.blog #calendar {
  display: none;
}

.blog .wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  width: 95%;
  padding: 50px auto !important;
  margin: 50px auto;
}

.blog .wrap aside {
  width: 25%;
}

@media screen and (max-width: 834px) {
  .blog .wrap aside {
    width: 100%;
  }
}

.blog .wrap aside button {
  margin-top: 10px;
  padding: 5px 20px;
}

.blog .wrap aside .pages li {
  margin-bottom: 10px;
  text-align: left;
}

.blog .wrap aside .pages li a:link {
  color: #23c5b5 !important;
}

.blog .wrap aside .pages li a:visited {
  color: #23c5b5 !important;
}

.blog .wrap aside .category li {
  margin-bottom: 10px;
  text-align: left;
}

.blog .wrap aside .category li a:link {
  color: #23c5b5 !important;
}

.blog .wrap aside .category li a:visited {
  color: #23c5b5 !important;
}

.blog .wrap aside h3 {
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: rgba(35, 197, 181, 0.1);
  margin: 20px 0;
  border: 1px solid #23c5b5;
}

.blog .wrap #contents {
  width: 70% !important;
}

@media screen and (max-width: 834px) {
  .blog .wrap #contents {
    text-align: left;
  }
}

.blog .wrap #contents dl {
  padding: 10px 0;
  border-bottom: 1px #23c5b5 dotted;
}

.blog .wrap #contents dl dt {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.blog .wrap #contents article {
  padding: 10px 0;
  border-bottom: 1px #23c5b5 dotted;
}

@media screen and (max-width: 834px) {
  .blog .wrap #contents .detail {
    text-align: left;
    margin-top: 30px !important;
  }
}

.blog .wrap #contents .date {
  font-size: 16px;
  color: #23c5b5;
  font-weight: bold;
  text-align: left !important;
}

.blog .wrap #contents h2 {
  font-size: 28px;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #23c5b5;
}

@media screen and (max-width: 834px) {
  .blog .wrap #contents h2 {
    text-align: left !important;
  }
}

.blog .wrap #contents ul {
  margin-bottom: 10px;
}

.blog .wrap #contents span.translate {
  padding-right: 5px;
  display: block;
  font-size: 24px;
  margin: 10px 0;
}

#prof {
  padding-bottom: 10px;
  display: block;
}

#prof_text {
  padding-top: 30px;
  text-align: left;
}

#prof_detail {
  text-align: center;
}

#prof_detail img {
  margin: 0 auto !important;
}

.pager a {
  display: block !important;
  margin-left: 0px !important;
  margin-bottom: 20px;
  width: auto !important;
  padding: 5px;
  height: auto !important;
  line-height: 32px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #23c5b5;
  border-radius: 0px;
  text-shadow: none !important;
  border: 1px solid #ccc !important;
  color: #base-color !important;
  text-align: center;
  text-indent: -1px;
  float: none !important;
  text-decoration: none;
  margin-top: 20px;
}

/*********************テーブル画像***********************/
.img_table04 table tr {
  display: flex;
  flex-wrap: wrap;
}

.img_table04 table tr td {
  display: block;
  padding: 0 !important;
  width: 23%;
  margin: 20px 1% 0 1% !important;
}

@media screen and (max-width: 640px) {
  .img_table04 table tr td {
    margin: 10px 1% 0 1% !important;
    width: 48% !important;
    margin: 0 auto;
    text-align: center;
  }
}

.img_table02 table tr {
  display: flex;
  flex-wrap: wrap;
}

.img_table02 table tr td {
  display: block;
  padding: 0 !important;
  width: 48%;
  margin: 20px 1% 20px 1% !important;
}

@media screen and (max-width: 640px) {
  .img_table02 table tr td {
    margin: 10px 1% 0 1% !important;
    width: 48% !important;
    margin: 0 auto;
    text-align: center;
  }
}

.img_table03 table tr {
  display: flex;
  flex-wrap: wrap;
}

.img_table03 table tr td {
  display: block;
  padding: 0 !important;
  width: 31.111111%;
  margin: 20px 1% 0 1% !important;
}

@media screen and (max-width: 640px) {
  .img_table03 table tr td {
    margin: 10px 1% 0 1% !important;
    width: 48% !important;
    margin: 0 auto;
    text-align: center;
  }
}

header#global_header {
  max-width: 100%;
  min-width: 100%;
}

#header {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 5;
  align-items: center;
}

@media screen and (max-width: 834px) {
  #header {
    position: static;
    padding-top: 48px;
  }
}

.cnt_header {
  background: #000 !important;
}

@media screen and (max-width: 834px) {
  div#builingual {
    padding-top: 53px !important;
  }
}

.logo {
  display: flex;
}

@media screen and (max-width: 834px) {
  .logo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 180px;
    padding-top: 5px;
  }
}

.hedaer_right {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#hedaer_info {
  display: flex;
}

@media screen and (max-width: 834px) {
  #hedaer_info {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 70%;
  }
}

#hedaer_info li img {
  height: 80px !important;
}

@media screen and (max-width: 834px) {
  #hedaer_info li img {
    height: auto !important;
  }
}

#nav_global {
  width: 100%;
  max-width: 650px;
}

#nav_global ul {
  margin: 0 auto;
  max-width: 1000px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 834px) {
  #nav_global ul {
    display: block;
  }
}

#nav_global ul li {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
}

@media screen and (max-width: 834px) {
  #nav_global ul li {
    width: 50%;
  }
}

#nav_global ul li a {
  color: #fff;
  text-decoration: none;
  padding: 28px 0;
  display: block;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

#nav_global ul li a span {
  display: block;
}

#nav_global ul ul {
  z-index: 99;
  position: absolute;
  width: 340px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

#nav_global ul ul li {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  width: 170px;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
}

#nav_global ul ul li a {
  padding: 10px;
  font-size: 14px;
}

#nav_global ul .sales_nav {
  display: block;
  z-index: 99;
  position: absolute;
  width: 100%;
}

#nav_global ul .sales_nav li {
  width: auto;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
}

#nav_global ul .sales_nav li a {
  padding: 10px;
  font-size: 14px;
}

.flex_box {
  max-width: 1000px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .flex_box {
    display: block;
  }
}

.flR #hed_info {
  margin-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 834px) {
  .flR #hed_info {
    -webkit-justify-content: center;
    justify-content: center;
  }
}

.flR #hed_info .head_mail {
  margin-left: 10px;
}

@media screen and (max-width: 834px) {
  .flR #hed_info .head_mail {
    margin-left: 5px;
  }
}

.flR #hed_info .head_tel {
  margin-left: 10px;
  margin-right: 5px;
}

.flR p {
  text-align: right;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}

@media screen and (max-width: 834px) {
  .flR p {
    text-align: center;
  }
}

#mainArea {
  width: 100% !important;
  margin: 0 auto;
  position: relative;
  width: 100% !important;
}

@media screen and (max-width: 834px) {
  #mainArea {
    padding-top: 0px;
  }
}

@media screen and (max-width: 640px) {
  #mainArea {
    padding-top: 0px;
  }
}

#mainArea img {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  #mainArea img {
    height: 500px !important;
  }
}

@media screen and (max-width: 640px) {
  #mainArea img {
    height: 400px !important;
  }
}

#mainArea .catch {
  height: 100%;
  max-width: 1040px;
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  height: 140px !important;
  z-index: 5;
}

#mainArea .catch p {
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (max-width: 640px) {
  #mainArea .catch p span {
    display: block;
  }
}

#mainArea .catch img {
  height: auto !important;
}

@media screen and (max-width: 640px) {
  #mainArea .catch img {
    width: 70% !important;
  }
}

#mainArea .slick-prev, #mainArea .slick-next {
  display: none !important;
}

#mainArea ul li img {
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  #mainArea {
    width: auto !important;
  }
}

.mean-container .mean-bar {
  background: #053833 !important;
  position: fixed !important;
}

.mean-container .mean-nav {
  background: #053833 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li ul {
  /*background-color: #e9832f !important;*/
  display: flex;
  flex-wrap: wrap;
}

footer#global_footer {
  min-width: auto !important;
}

#footer {
  padding-top: 50px;
  font-size: 13px;
  font-weight: bold;
  background: #111111 !important;
}

#nav_footer {
  padding: 20px 0;
  font-weight: bold;
  font-size: 14px;
  background: #111111;
}

#nav_footer ul {
  max-width: 1000px;
  margin: 5px auto;
}

@media screen and (max-width: 834px) {
  #nav_footer ul {
    max-width: 100%;
    width: 80%;
  }
}

#nav_footer ul li {
  display: inline-block;
  margin: 5px 25px;
  width: auto;
}

@media screen and (max-width: 834px) {
  #nav_footer ul li {
    display: block;
    margin: 5px 0;
  }
}

#nav_footer ul li a {
  font-size: 16px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff !important;
  text-decoration: none;
}

@media screen and (max-width: 834px) {
  #nav_footer ul li a {
    background: #fff !important;
    padding: 10px;
    display: block;
    border-radius: 10px;
    font-weight: bold;
  }
  #nav_footer ul li a:link {
    color: #111 !important;
    text-decoration: none;
  }
  #nav_footer ul li a:visited {
    color: #111 !important;
  }
}

#pagetop {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
}

@media screen and (max-width: 640px) {
  #pagetop {
    bottom: 0;
    right: 0;
    width: 30%;
    max-width: 80px;
  }
  #pagetop img {
    width: 100% !important;
    height: auto;
  }
}

.footer_info address {
  color: #fff !important;
}

.footer_info address ul {
  margin: 0 auto;
}

.footer_info address ul li {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .footer_info address ul li {
    width: 100%;
  }
}

.footer_info address ul .footer_address {
  padding-top: 30px;
  font-size: 14px;
  display: block;
  font-weight: bold;
  text-align: center;
}

.footer_info address ul .footer_tel {
  padding-top: 20px;
  font-size: 20px;
  display: block;
  font-weight: bold;
  text-align: center;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.footer_info address ul .footer_tel a {
  color: #fff !important;
}

#copy {
  background: #000000 !important;
  color: #fff;
  padding: 18px;
  display: block !important;
  font-size: 16px !important;
}

@media screen and (max-width: 834px) {
  #copy {
    padding-bottom: 100px;
  }
}

#copy a:link {
  color: #fff;
  text-decoration: none;
}

#copy a:hover {
  text-decoration: underline;
}

#copy a:visited {
  color: #fff;
  text-decoration: none;
}

#main #col_main {
  width: 100%;
}

#global_footer {
  height: 40px;
}

#contents {
  padding-top: 80px;
  width: 100% !important;
  max-width: 1000px !important;
  padding-bottom: 100px;
  background-color: #333;
}

@media screen and (max-width: 1000px) {
  #contents {
    padding: 0 20px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding: 0 20px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  #contents {
    padding: 0 10px;
    padding-bottom: 30px;
  }
}

#contents a {
  color: #23c5b5;
}

#main {
  width: 100% !important;
}

.nega {
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
}

.nega_bottom {
  margin-bottom: -100px;
}

@media screen and (max-width: 834px) {
  .nega_bottom {
    margin-bottom: -50px;
  }
}

@media screen and (max-width: 640px) {
  .nega_bottom {
    margin-bottom: -30px;
  }
}

/* TOP */
.top01 {
  display: flex;
}

@media screen and (max-width: 834px) {
  .top01 {
    display: block;
  }
}

.top01_img {
  width: 50%;
  background-size: cover;
  background-position: left;
}

@media screen and (max-width: 834px) {
  .top01_img {
    width: 100%;
    background-position: center;
  }
}

.top01_text {
  width: 50%;
}

@media screen and (max-width: 834px) {
  .top01_text {
    width: 100%;
  }
}

.top01_text article {
  max-width: 500px;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 834px) {
  .top01_text article {
    margin: 0 auto;
  }
}

.top01_text article h1 {
  font-size: 36px;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  padding-bottom: 30px;
}

.top01_text article h1:after {
  content: "";
  width: 100%;
  height: 1px;
  background: url(../images/common/h_line01.png);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.top01_text article h1 span {
  font-size: 24px;
  margin-left: 20px;
}

@media screen and (max-width: 640px) {
  .top01_text article h1 span {
    display: block;
  }
}

.top01_text article div {
  padding-top: 30px;
  color: #fff;
}

.top02 .top02_inner {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  padding: 50px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .top02 .top02_inner {
    padding: 20px;
  }
}

@media screen and (max-width: 834px) {
  .top02 .top02_inner {
    padding: 30px;
  }
}

.top02 .top02_inner:after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  background: url(../images/common/top02_waku01.png);
}

.top02 .top02_inner:before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
  background: url(../images/common/top02_waku02.png);
}

.top02 .top02_inner01 {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 640px) {
  .top02 .top02_inner01 {
    flex-direction: column;
  }
}

.top02 .top02_inner01 .top02_text {
  width: 60%;
}

@media screen and (max-width: 834px) {
  .top02 .top02_inner01 .top02_text {
    width: 70%;
  }
}

@media screen and (max-width: 640px) {
  .top02 .top02_inner01 .top02_text {
    width: 100%;
  }
}

.top02 .top02_inner01 .top02_text table .text_btn a {
  display: block;
  color: #fff !important;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #fff;
  text-align: center;
  text-decoration: none;
  max-width: 200px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin: 0 0 0 auto;
}

.top02 .top02_inner01 .top02_text table .titletext {
  padding-top: 0px !important;
}

@media screen and (max-width: 640px) {
  .top02 .top02_inner01 .top02_text table .titletext {
    padding-top: 20px !important;
  }
}

.top02 .top02_inner01 .top02_text table td {
  border: 0 !important;
}

.top02 .top02_inner01 .top02_text table td div {
  color: #fff;
  font-size: 14px;
}

.top02 .top02_inner01 .top02_text table td h2 {
  font-size: 36px;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  padding-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .top02 .top02_inner01 .top02_text table td h2 {
    font-size: 24px;
  }
}

.top02 .top02_inner01 .top02_text table td h2 span {
  font-size: 24px;
  margin-left: 20px;
}

@media screen and (max-width: 834px) {
  .top02 .top02_inner01 .top02_text table td h2 span {
    font-size: 18px;
  }
}

@media screen and (max-width: 640px) {
  .top02 .top02_inner01 .top02_text table td h2 span {
    display: block;
    margin-left: 0;
  }
}

.top02 .top02_inner01 .top02_text table td h2:after {
  content: "";
  width: 100%;
  height: 1px;
  background: url(../images/common/h_line01.png);
  background-size: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.top02 .top02_inner01 .top02_img {
  width: 40%;
}

@media screen and (max-width: 834px) {
  .top02 .top02_inner01 .top02_img {
    width: 30%;
  }
}

@media screen and (max-width: 640px) {
  .top02 .top02_inner01 .top02_img {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .top02 .sp_rev {
    flex-direction: column-reverse;
  }
}

.insta_title article {
  position: relative;
}

.insta_title article h2 {
  text-align: center !important;
  color: #fff;
  font-size: 32px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
  position: relative;
  z-index: 2;
}

.insta_title article:after {
  content: "";
  width: 52px;
  height: 52px;
  background: url(../images/common/insta_icon.png);
  background-size: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}

.waku01 {
  box-shadow: 0 0 5px #000;
  background-color: #484848;
}

.waku02 {
  border: 1px dotted #fff;
}

/*****************共通クラス*******************************/
.cnt_h1 {
  background: url(../images/common/main01.jpg);
  padding: 80px 0;
  background-position: center;
  background-size: cover;
}

.cnt_h1 h1 {
  text-align: center !important;
  font-size: 36px;
  font-weight: bold;
  display: block;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
}

@media screen and (max-width: 640px) {
  .cnt_h1 h1 {
    font-size: 24px;
  }
}

.cnt_h1 h1 span {
  font-size: 20px;
  display: block;
}

/***********h2*********************/
.cnt_h2 h2 {
  font-size: 28px;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  padding-bottom: 20px;
}

@media screen and (max-width: 640px) {
  .cnt_h2 h2 {
    font-size: 20px;
  }
}

.cnt_h2 h2:after {
  content: "";
  width: 100%;
  height: 1px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#23c5b5+0,484848+100 */
  background: #23c5b5;
  /* Old browsers */
  background: -moz-linear-gradient(left, #23c5b5 0%, #484848 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #23c5b5 0%, #484848 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #23c5b5 0%, #484848 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#23c5b5', endColorstr='#484848',GradientType=1 );
  /* IE6-9 */
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.cnt_h2 h2 span {
  font-size: 24px;
  margin-left: 20px;
}

/***********h3*********************/
.cnt_h3 h3 {
  font-size: 20px;
  padding: 10px;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
  line-height: 1.2;
  position: relative;
  background: #333;
  padding-bottom: 10px;
}

@media screen and (max-width: 640px) {
  .cnt_h3 h3 {
    font-size: 18px;
  }
}

/***************タイトル04***************/
.cnt_h4 h4 {
  display: block;
  position: relative;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding: 10px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.1);
}

/***************タイトル01***************/
.cnt_title01 h3 {
  display: block;
  padding: 10px;
  position: relative;
  border-top: 1px solid #23c5b5;
  border-bottom: 1px solid #23c5b5;
  font-size: 20px;
  color: #23c5b5;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-left: 3px solid #23c5b5;
  border-right: 1px solid #23c5b5;
  background: #ede5ff;
  margin: 10px 0;
  position: relative;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .cnt_title01 h3 {
    font-size: 20px;
  }
}

/***************タイトル02***************/
.cnt_title02 h3 {
  display: block;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  font-weight: bold;
  border-bottom: 1px solid #23c5b5;
  border-left: 5px solid #23c5b5;
  font-size: 20px;
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  background: #053833;
}

.cnt_title02 h3 span {
  background: #23c5b5;
  display: inline-block;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
}

/***************タイトル03***************/
.cnt_title03 h3 {
  display: block;
  padding-bottom: 10px;
  position: relative;
  font-weight: bold;
  border-bottom: 1px dotted #23c5b5;
  font-size: 24px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (max-width: 834px) {
  .cnt_title03 h3 {
    text-align: center !important;
    font-size: 20px;
  }
}

.cnt_title03 h3 span {
  background: #23c5b5;
  display: inline-block;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
}

@media screen and (max-width: 834px) {
  .cnt_title03 h3 span {
    display: block;
    text-align: center;
    margin-bottom: 5px;
    margin-right: 0px;
  }
}

/***************タイトル04***************/
.cnt_title04 h4 {
  display: block;
  position: relative;
  font-size: 20px;
  color: #23c5b5;
  background: #333;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding: 5px;
}

/***************タイトル05***************/
.cnt_title05 h5 {
  display: block;
  position: relative;
  border: 1px dotted #23c5b5;
  font-size: 18px;
  color: #23c5b5;
  background: #333;
  font-weight: bold;
  padding: 5px;
}

/******************強調テキスト**************************************/
.emphasis_text {
  font-size: 38px;
  color: #23c5b5;
  text-align: center !important;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
  padding: 30px 0 !important;
}

@media screen and (max-width: 834px) {
  .emphasis_text {
    font-size: 22px;
  }
}

/******************dl リスト01**************************************/
.dldt_list01 {
  display: block;
  width: 100%;
  margin: 0 1%;
}

@media screen and (max-width: 834px) {
  .dldt_list01 {
    width: 100%;
    margin: 10px auto;
  }
}

.dldt_list01 dl {
  display: block !important;
  width: 100%;
}

.dldt_list01 dl dt {
  font-weight: bold;
  background: #333 !important;
  color: #23c5b5 !important;
  display: block !important;
  width: 100% !important;
  border: 0px !important;
}

.dldt_list01 dl dd {
  display: block !important;
  width: 100% !important;
  border: 0px !important;
}

/******************dl リスト02**************************************/
.dldt_list02 {
  display: block;
  width: 100%;
}

.dldt_list02 dl {
  width: 100%;
  background: #333 !important;
}

@media screen and (max-width: 640px) {
  .dldt_list02 dl {
    display: block !important;
  }
}

.dldt_list02 dl:nth-child(odd) {
  background: #222 !important;
}

.dldt_list02 dl dt {
  font-weight: bold;
  color: #fff !important;
  border: 0px !important;
  background: none !important;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .dldt_list02 dl dt {
    display: block !important;
    width: 100% !important;
    background: #333 !important;
    text-align: left;
  }
}

.dldt_list02 dl dd {
  border: 0px !important;
  background: none !important;
}

@media screen and (max-width: 640px) {
  .dldt_list02 dl dd {
    display: block !important;
    width: 100% !important;
    background: #222 !important;
  }
}

/*******************チェック　ul(AI用)******************************/
.check_ul01 {
  padding: 50px 0;
}

.check_ul01 ul {
  max-width: 650px;
  margin: 0 auto;
  display: block !important;
}

.check_ul01 ul li {
  float: none !important;
  display: block !important;
  font-size: 20px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #2a2e67;
  font-weight: bold;
  padding-left: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #2a2e67;
  position: relative;
}

.check_ul01 ul li:after {
  content: "";
  width: 51px;
  height: 26px;
  display: block;
  position: absolute;
  left: 5px;
  top: 10px;
  background: url(../images/common/list_icon.png);
}

/*******************お写真紹介 table********************/
#fancybox-title-float-wrap td {
  padding: 0px 7px !important;
}

/***************新着情報***************************/
.newstitle {
  max-width: 1100px;
  margin: 0 auto;
}

.newstitle h2 {
  color: #fff;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  padding-left: 110px;
}

.newstitle h2:after {
  content: "";
  width: calc(100% - 260px);
  height: 1px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-bottom: 1px solid #23c5b5;
}

.newstitle h2:before {
  content: "";
  width: 60px;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-bottom: 1px solid #23c5b5;
}

/******************新着情報　AI用***************************/
.top_scroll {
  width: 95%;
  max-width: 1100px;
  margin: 0 auto;
  height: 200px;
}

@media screen and (max-width: 834px) {
  .top_scroll {
    padding: 0;
    padding-top: 20px;
    width: 95%;
    height: 300px;
  }
}

@media screen and (max-width: 834px) {
  .top_scroll {
    width: 100%;
  }
}

.top_scroll dl {
  border-bottom: 1px dotted #fff;
  padding: 10px 0;
  display: block !important;
}

.top_scroll dl dt {
  display: block !important;
  width: 100% !important;
  background: none !important;
  border: 0px !important;
  color: #fff !important;
  font-size: 18px;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  padding: 10px 5px 0 5px !important;
}

.top_scroll dl dd {
  display: block !important;
  width: 100% !important;
  background: none !important;
  border: 0px !important;
  padding: 0 5px 5px 5px !important;
  color: #fff;
}

.scroll {
  text-align: left;
}

.mCSB_dragger_bar {
  margin-top: 10px !important;
  background: #23c5b5 !important;
  width: 5px !important;
  border-radius: 0px !important;
}

/***********************AI用画像テキストタイトル*******************************************/
.fl_img_text .box {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

@media screen and (max-width: 640px) {
  .fl_img_text .box {
    display: block;
    text-align: center;
    margin-top: 50px;
  }
}

.fl_img_text .box img {
  width: 38% !important;
  object-fit: cover;
  height: 250px !important;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 640px) {
  .fl_img_text .box img {
    width: 80% !important;
    margin: 0 auto;
    text-align: center;
  }
}

.fl_img_text .box div {
  width: 58%;
  text-align: left !important;
}

@media screen and (max-width: 640px) {
  .fl_img_text .box div {
    width: 100%;
    padding-top: 20px;
  }
}

.fl_img_text .box div h2 {
  font-size: 24px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.fl_img_text .box:nth-child(even) {
  flex-direction: row-reverse;
}

.banner_flbtn {
  align-items: center;
  background: #333;
  justify-content: space-around;
}

.banner_flbtn .box {
  width: 31%;
  margin: 20px 1%;
}

/********************************/
.Bspec_table table tr {
  background: #333;
}

.Bspec_table table tr:nth-child(odd) {
  background: #222;
}

.Bspec_table table td {
  border: 0px !important;
}

@media screen and (max-width: 640px) {
  .Bspec_table table td {
    width: auto !important;
  }
}

/* title */
.blog_wrap {
  height: 450px;
  padding-right: 10px;
  overflow-y: auto;
}

.blog_wrap .blog_box {
  position: relative;
  margin-bottom: 30px;
}

.blog_wrap .blog_box a {
  transition: 1s;
  text-decoration: none;
  color: #fff !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.blog_wrap .blog_box a .left {
  width: 30%;
}

.blog_wrap .blog_box a .left img {
  width: 100% !important;
}

.blog_wrap .blog_box a .right {
  width: 68%;
}

.blog_wrap .blog_box a .right h2 {
  font-size: 18px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #fff;
}

.blog_wrap .blog_box a .right .meta {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1;
}

.blog_wrap .blog_box a .right .meta:before {
  transition: 1s;
  content: "";
  font-family: "Font Awesome 5 Pro";
  content: "\f017";
  font-weight: 700;
  vertical-align: text-bottom;
  margin-right: 7px;
}
